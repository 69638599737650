<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h2>Please complete your payment to avail this service!</h2>
            </v-col>
            <v-col cols="12">
                <v-btn color="green" :loading="loading" @click="payNow">Pay now</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
export default {
    data: () => ({ 
        loading: false
    }),

    computed: {
        buyer_name () {
        return this.$store.getters.loadedRoll
        },
        email () {
        return this.$store.getters.loadedUserData.email
        },
        phone () {
        return this.$store.getters.loadedUserData.phone
        },
    },

    methods: {
        payNow () {
            this.loading = true
            axios.post('https://us-central1-nrtest-5fa75.cloudfunctions.net/widgets/payreq', {
                purpose: 'Online class',
                amount: '3000',
                send_email: true,
                webhook: 'https://us-central1-nrtest-5fa75.cloudfunctions.net/widgets/mojoforclass',
                send_sms: false,
                allow_repeated_payments: false,
                name: this.buyer_name,
                email: this.email,
                phone: this.phone
            })
            .then(function (response) {
                window.location.replace(response.data);
                //this.$store.dispatch('createAlert', {type: 'info', message: response.data})
                this.loading = false
            })
            .catch(function (error) {
                alert(error.message)
                //this.$store.dispatch('createAlert', {type: 'info', message: error})
                this.loading = false
            });
        }
    }
}
</script>